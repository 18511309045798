import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import Button from "@atlaskit/button";
import { Checkbox } from "@atlaskit/checkbox";
import DynamicTable from "@atlaskit/dynamic-table";
import { HeadType, RowType } from "@atlaskit/dynamic-table/types";
import { ModalTransition } from "@atlaskit/modal-dialog";
import { AkTrashIcon } from "@fuegokit/fuegoicons-react";
import { Box } from "@fuegokit/react";

import "./dashboard.css";

import { useApplicationContext } from "../../providers/ApplicationContextProvider";
import { useFirebaseData } from "../../providers/FirebaseDataProvider";
import { useUsersStore } from "../../providers/UserStoreContextProvider";
import { GameFilters, GameState, JiraUser, Users } from "../../types";
import { normalizeBy } from "../../utils";
import { isGameAdmin } from "../../utils/game";
import { CheckboxCell } from "../shared/cells/CheckboxCell";
import { LoadingPage } from "../shared/LoadingPage";
import { AvatarItemCell } from "./cells/AvatarItemCell";
import { DateCell } from "./cells/DateCell";
import { GameLinkShareCell } from "./cells/GameLinkShareCell";
import { MoreActionsCell } from "./cells/MoreActionsCell";
import { StateCell } from "./cells/StateCell";
import { EmptyState } from "./EmptyState";
import { Filters } from "./Filters";
import { CloneGameModal, DeleteMultipleGamesModal, DeleteSingleGameModal, FinishGameModal } from "./modals/modals";

const MAX_VISIBLE_GAMES = 100;

const generateHeader = (
  someSelected: boolean,
  allSelected: boolean,
  isDisabled: boolean,
  toggleAllSelected: (value: boolean) => void,
): HeadType => ({
  cells: [
    {
      key: "id",
      content: (
        <Box paddingLeft={2}>
          <Checkbox
            isChecked={someSelected}
            isDisabled={isDisabled}
            isIndeterminate={someSelected && !allSelected}
            onChange={(e) => toggleAllSelected(e.target.checked)}
          />
        </Box>
      ),
    },
    { key: "game", content: "Game", isSortable: true, shouldTruncate: true },
    {
      key: "created",
      content: "Created",
      isSortable: true,
    },
    {
      key: "lastUsed",
      content: "Last Used",
      isSortable: true,
    },
    { key: "state", content: "State", isSortable: true },
    { key: "creator", content: "Creator", isSortable: true, shouldTruncate: true },
    { key: "actions", content: "" },
  ],
});

const defaultFilters: GameFilters = {
  creator: [],
  gameState: [GameState.ACTIVE],
  search: "",
};

export const GamesList = () => {
  const navigate = useNavigate();
  const { games, isLoading, getGames } = useFirebaseData();
  const [creators, setCreators] = useState<Users>();
  const [selectedGames, setSelectedGames] = useState<string[]>([]);
  const { isJiraAdmin, userAccountId } = useApplicationContext();
  const { queryByAccountIds } = useUsersStore();

  const [filters, setFilters] = useState<GameFilters>(defaultFilters);
  const filteredGames = useMemo(() => {
    let results = [...games];

    if (filters.search.length) {
      results = results.filter((result) =>
        result.configuration.name.toLocaleLowerCase().includes(filters.search.toLocaleLowerCase()),
      );
    }

    if (filters.creator.length) {
      results = results.filter((result) => filters.creator.includes(result.creator));
    }

    if (filters.gameState.length) {
      results = results.filter((result) => filters.gameState.includes(result.state));
    }

    const mostRecentGames = results.sort((a, b) => b.updated - a.updated).slice(0, MAX_VISIBLE_GAMES);
    return mostRecentGames;
  }, [filters, games]);
  const gamesIds = useMemo(
    () =>
      filteredGames
        .filter((game) => isGameAdmin(userAccountId, game.configuration.admins) || isJiraAdmin)
        .map((game) => game.id),
    [filteredGames, userAccountId, isJiraAdmin],
  );
  const toggleOneSelected = useCallback(
    (gameId: string, isDisabled) => () => {
      !isDisabled &&
        setSelectedGames((prev) => {
          if (!prev.find((id) => id === gameId)) {
            return [...prev, gameId];
          } else {
            return prev.filter((id) => id !== gameId);
          }
        });
    },
    [setSelectedGames],
  );
  const toggleAllSelected = useCallback(
    (isChecked: boolean) => {
      if (isChecked) {
        setSelectedGames(gamesIds);
      } else {
        setSelectedGames([]);
      }
    },
    [gamesIds, setSelectedGames],
  );

  const someSelected = useMemo(() => !!selectedGames.length, [selectedGames]);
  const allSelected = useMemo(() => selectedGames.length === gamesIds?.length, [selectedGames, gamesIds]);
  const userIds = useMemo(() => games.map((game) => game.creator), [games]);
  const uniqueUserIds = useMemo(() => [...new Set([userAccountId, ...userIds])], [userIds, userAccountId]);

  useEffect(() => {
    queryByAccountIds(uniqueUserIds)
      .then((users) => {
        setCreators(normalizeBy<JiraUser, "accountId">(users, "accountId"));
      })
      .catch((error) => {
        console.error(error);
      });
  }, [queryByAccountIds, uniqueUserIds]);

  const gameRows: RowType[] = useMemo(() => {
    if (!filteredGames || !creators) {
      return [];
    }
    return filteredGames.map((game) => {
      const isAdmin = isGameAdmin(userAccountId, game.configuration.admins) || isJiraAdmin;
      return {
        key: `${game.id}`,

        cells: [
          {
            key: game.id,
            onClick: toggleOneSelected(game.id, !isAdmin),
            content: (
              <Box paddingLeft={2}>
                <CheckboxCell id={game.id} isDisabled={!isAdmin} selectedIds={selectedGames} />
              </Box>
            ),
          },
          {
            key: game.configuration.name,
            content: <GameLinkShareCell gameId={game.id} gameName={game.configuration.name} />,
          },

          {
            key: String(game.created),
            content: <DateCell timestamp={game.created} />,
          },
          {
            key: String(game.updated),
            content: <DateCell timestamp={game.updated} />,
          },
          {
            key: game.state,
            content: <StateCell gameState={game.state} />,
          },
          {
            key: creators[game.creator]?.displayName,
            content: <AvatarItemCell creator={creators[game.creator]} />,
          },
          {
            key: game.id,
            content: (
              <Box paddingRight={2}>
                <MoreActionsCell gameId={game.id} game={game} />
              </Box>
            ),
          },
        ],
      };
    }) as RowType[];
  }, [selectedGames, filteredGames, creators, toggleOneSelected, isJiraAdmin, userAccountId]);

  function updateSelection(gameId: string) {
    setSelectedGames((prev) => prev.filter((id) => id !== gameId));
  }

  function reFetchGames() {
    try {
      void getGames();
    } catch (e) {
      console.error("Failed to fetch games");
    }
  }

  if (isLoading || !creators) {
    return <LoadingPage />;
  } else {
    return (
      <Box>
        <Box display="flex" marginBottom="8px" justifyContent="space-between" alignItems="end">
          <Filters filters={filters} setFilters={setFilters} creators={creators} />
          <Button
            appearance="warning"
            onClick={() => navigate("/delete-games")}
            isDisabled={selectedGames.length === 0}
            iconBefore={<AkTrashIcon />}
          >
            {`Delete ${selectedGames.length || ""} ${selectedGames.length > 1 ? "games" : "game"}`}
          </Button>
        </Box>
        <DynamicTable
          head={generateHeader(someSelected, allSelected, !gamesIds.length, toggleAllSelected)}
          rows={gameRows}
          defaultSortKey="lastUsed"
          defaultSortOrder="DESC"
          emptyView={<EmptyState />}
        />
        <ModalTransition>
          <Routes>
            <Route path="/finish-game/:id" element={<FinishGameModal onListUpdated={reFetchGames} />} />
            <Route path="/clone-game/:id" element={<CloneGameModal />} />
            <Route
              path="/delete-game/:id"
              element={
                <DeleteSingleGameModal
                  onListUpdated={(gameId: string) => {
                    updateSelection(gameId);
                    reFetchGames();
                  }}
                />
              }
            />
            <Route
              path="/delete-games"
              element={
                <DeleteMultipleGamesModal
                  selected={selectedGames}
                  onListUpdated={() => {
                    setSelectedGames([]);
                    reFetchGames();
                  }}
                />
              }
            />
          </Routes>
        </ModalTransition>
      </Box>
    );
  }
};
