import React from "react";

import SectionMessage from "@atlaskit/section-message";
import { Box } from "@fuegokit/react";

export function Unavailable() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <SectionMessage title="Extension Point Unavailable" appearance="error">
        <p>This Jira extension point is unavailable. Please update Planning Poker to fix it.</p>
      </SectionMessage>
    </Box>
  );
}
