import React from "react";
import * as ReactDOM from "react-dom";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";

import { ModalTransition } from "@atlaskit/modal-dialog";

import "@atlaskit/css-reset";
import "../main.css";

import { AnalyticsContextProvider } from "../../analytics/AnalyticsContextProvider";
import { Dashboard } from "../../components/dashboard/Dashboard";
import { WelcomeScreen } from "../../components/dashboard/WelcomeScreen";
import { EditGameModal } from "../../components/game/EditGameModal";
import { GameDeleteGameModal } from "../../components/game/GameDeleteGameModal";
import { GameFinishGameModal } from "../../components/game/GameFinishGameModal";
import { GameIssuesProvider } from "../../components/game/GameIssuesProvider";
import { GameProvider } from "../../components/game/GameProvider";
import { GameRestartGameModal } from "../../components/game/GameRestartGameModal";
import { Header } from "../../components/Header";
import { LoadingPage } from "../../components/shared/LoadingPage";
import { ParticipantPresenceController } from "../../components/shared/ParticipantsPresenceController";
import { ApplicationContextProvider } from "../../providers/ApplicationContextProvider";
import { BacklogStoreContextProvider } from "../../providers/BacklogStoreContextProvider";
import { ErrorBoundary } from "../../providers/BugsnagErrorBoundary";
import { EpicsDetailsStoreContextProvider } from "../../providers/EpicsDetailsProvider";
import { FirebaseDataContextProvider, useFirebaseData } from "../../providers/FirebaseDataProvider";
import { FirebaseProvider } from "../../providers/FirebaseProvider";
import { JiraDataStoreContextProvider } from "../../providers/JiraDataStoreProvider";
import { ThemeProvider } from "../../providers/ThemeProvider";
import { UsersDataStoreContextProvider } from "../../providers/UserStoreContextProvider";
import { CreateNewGameDialog } from "../../screens/CreateNewGameDialog";
import { EditBacklog } from "../../screens/EditBacklog";
import { Game } from "../../screens/Game";
import { Unavailable } from "../../screens/Unavailable";
import { ConnectRouter } from "../../services/ConnectRouter";
import { getIframeUrlParameterByName } from "../../utils";

const ContentWrapper = styled.div`
  padding-top: 24px;
  padding-left: 8px;
  min-height: 0;
`;

const MainLayoutContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1069px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

function MainLayout() {
  return (
    <MainLayoutContainer data-testid="main-layout">
      <Outlet />
    </MainLayoutContainer>
  );
}

const Main = () => {
  const { games, isLoading } = useFirebaseData();
  const location = useLocation();

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      {games.length ? (
        <ContentWrapper>
          <Header />
          <Dashboard />
        </ContentWrapper>
      ) : (
        <WelcomeScreen />
      )}
      <BacklogStoreContextProvider>
        <CreateNewGameDialog isOpen={location.pathname === "/create-game"} />
      </BacklogStoreContextProvider>
    </>
  );
};

const GameLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 850px;
`;

function GameLayout() {
  return (
    <ParticipantPresenceController>
      <GameProvider>
        <BacklogStoreContextProvider>
          <GameIssuesProvider>
            <GameLayoutContainer>
              <ModalTransition>
                <Routes>
                  <Route path="/game/:id/backlog" element={<EditBacklog />} />
                  <Route path="/game/:id/configuration" element={<EditGameModal />} />
                  <Route path="/game/:id/restart-game" element={<GameRestartGameModal />} />
                  <Route path="/game/:id/finish-game" element={<GameFinishGameModal />} />
                  <Route path="/game/:id/delete-game" element={<GameDeleteGameModal />} />
                </Routes>
              </ModalTransition>
              <Outlet />
            </GameLayoutContainer>
          </GameIssuesProvider>
        </BacklogStoreContextProvider>
      </GameProvider>
    </ParticipantPresenceController>
  );
}

function App() {
  const deprecatedFromBacklogQueryParam = getIframeUrlParameterByName("from-backlog");

  if (deprecatedFromBacklogQueryParam) {
    return <Unavailable />;
  }

  return (
    <ErrorBoundary>
      <ThemeProvider>
        <ConnectRouter>
          <ApplicationContextProvider>
            <AnalyticsContextProvider>
              <FirebaseProvider>
                <JiraDataStoreContextProvider>
                  <UsersDataStoreContextProvider>
                    <EpicsDetailsStoreContextProvider>
                      <Routes>
                        <Route element={<MainLayout />}>
                          <Route
                            path="/*"
                            element={
                              <FirebaseDataContextProvider>
                                <Main />
                              </FirebaseDataContextProvider>
                            }
                          />
                        </Route>
                        <Route element={<GameLayout />}>
                          <Route path="/game/:id/:action?" element={<Game />} />
                        </Route>
                      </Routes>
                    </EpicsDetailsStoreContextProvider>
                  </UsersDataStoreContextProvider>
                </JiraDataStoreContextProvider>
              </FirebaseProvider>
            </AnalyticsContextProvider>
          </ApplicationContextProvider>
        </ConnectRouter>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
