import { AxiosError } from "axios";
import { SyntheticEvent } from "react";

import { showFlag } from "../services/jira-api";
import { Game, GameWithId, JIRA_FIELD_TYPE, JiraField, JiraFieldSchema, JiraIssue } from "../types";

export function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;
  return String(error);
}

export const DECK_TYPES = [
  { label: "Standard Fibonacci (0...55)", value: "fibonacci" },
  { label: "Modified Fibonacci (0...100)", value: "modified-fibonacci" },
  { label: "T-Shirt (XXS...XXL)", value: "t-shirt" },
  { label: "Labeled T-Shirt (XXS=0.5...XXL=13)", value: "labeled-t-shirt" },
  { label: "Hours (1-12)", value: "1-12" },
  { label: "Custom", value: "custom" },
];

export const DECKS: { [key: string]: string[] } = {
  fibonacci: ["0", "1", "2", "3", "5", "8", "13", "21", "34", "55"],
  "modified-fibonacci": ["0", "0.5", "1", "2", "3", "5", "8", "13", "20", "40", "60", "100"],
  "t-shirt": ["XXS", "XS", "S", "M", "L", "XL", "XXL"],
  "labeled-t-shirt": ["XXS=0.5", "XS=1", "S=2", "M=3", "L=5", "XL=8", "XXL=13"],
  "1-12": ["1h", "2h", "3h", "4h", "5h", "6h", "7h", "8h", "9h", "10h", "11h", "12h"],
};

export const DEFAULT_ESTIMATION_FIELD_NAME = "Story Points";

export function chunkArray<T>(array: T[], chunkSize: number): T[][] {
  return Array.from({ length: Math.ceil(array.length / chunkSize) }, (v, i) =>
    array.slice(i * chunkSize, i * chunkSize + chunkSize),
  );
}

export const getFormattedDate = (timestamp: number) => new Date(timestamp).toLocaleDateString();

export const copyToClipBoard = (e: SyntheticEvent, textToCopy: string) => {
  const textarea = document.createElement("textarea");
  textarea.textContent = textToCopy;
  textarea.style.position = "fixed";
  e.currentTarget.appendChild(textarea);
  textarea.select();
  try {
    document.execCommand("copy");
    showFlag("Game url is copied to clipboard", "You can share it with Game participants now", "success");
  } catch (error) {
    console.warn("Copy to clipboard failed.", error);
  } finally {
    e.currentTarget.removeChild(textarea);
  }
};

export const TIME_FIELDS = {
  timeoriginalestimate: "originalEstimate",
  timeestimate: "remainingEstimate",
};

export function findUserLastGame(games: GameWithId[], currentUserId: string) {
  return [...games.filter((game) => game.creator === currentUserId)].sort((a, b) => b.created - a.created)[0];
}

export function getVoteSetName(selected: Array<string | number>) {
  const [name] = Object.entries<string[] | number[]>(DECKS).find(([, values]) => {
    return values.length === selected.length && values.every((v) => selected.includes(v));
  }) || ["custom"];
  return name;
}

export function compareArrays(firstArray: string[], secondArray: string[]) {
  const firstSet = new Set(firstArray);
  const secondSet = new Set(secondArray);

  const addedItems = [...secondSet].filter((item) => !firstSet.has(item));
  const removedItems = [...firstSet].filter((item) => !secondSet.has(item));

  return { addedItems, removedItems };
}

export const FULL_PAGE_DIALOG_CONTROLS_WIDTH = "525px";
export const FULL_PAGE_DIALOG_MAX_WIDTH = "1069px";

export function normalizeBy<T, K extends keyof T>(array: T[], key: K): Record<string, T> {
  return array.reduce(
    (acc, curr) => {
      acc[String(curr[key])] = curr;
      return acc;
    },
    {} as Record<string, T>,
  );
}

export const isEllipsisActive = (el: HTMLDivElement | null, showTooltipOnEqualWidth: boolean) => {
  const elementChild = el?.firstElementChild as HTMLSpanElement;
  if (!elementChild || !el) return false;
  return showTooltipOnEqualWidth
    ? elementChild.offsetWidth >= el.offsetWidth
    : elementChild.offsetWidth > el.offsetWidth;
};

export function hasEpic(issue: JiraIssue) {
  return issue.fields.parent && !issue.fields.issuetype?.subtask;
}

export const ISSUE_COLOR_FIELD_SCHEMA = "com.pyxis.greenhopper.jira:jsw-issue-color";
export const SPRINT_FIELD_SCHEMA = "com.pyxis.greenhopper.jira:gh-sprint";
export const TEXTFIELD_FIELD_SCHEMA = "com.atlassian.jira.plugin.system.customfieldtypes:textfield";
export const URL_FIELD_SCHEMA = "com.atlassian.jira.plugin.system.customfieldtypes:url";

export function separateFoundAndMissing<T>(
  ids: string[],
  store: Record<string, T>,
  findPredicate?: (itemId: string) => boolean,
) {
  const { foundItems, missingIds } = ids.reduce<{ foundItems: T[]; missingIds: string[] }>(
    (acc, curr) => {
      if (findPredicate ? findPredicate(curr) : store[curr]) {
        acc.foundItems.push(store[curr]);
      } else {
        acc.missingIds.push(curr);
      }
      return acc;
    },
    { foundItems: [], missingIds: [] },
  );
  return { foundItems, missingIds };
}

export function allIncluded<T>(array1: T[], array2: T[]) {
  return array1.every((element) => array2.includes(element));
}

export function upperCaseFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function groupByIssueKey(array: JiraIssue[]) {
  return array.reduce<Record<string, JiraIssue>>((result, issue) => {
    result[issue.key] = issue;
    return result;
  }, {});
}

export const getGlobalSettingsRelativeUrl = (addonKey: string) =>
  `/plugins/servlet/ac/${addonKey}/planning-poker-configuration-admin-panel`;

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const isStringTypeSupported = (field: JiraField) =>
  field.schema?.type !== JIRA_FIELD_TYPE.STRING ||
  [TEXTFIELD_FIELD_SCHEMA, URL_FIELD_SCHEMA].includes(field.schema.custom ?? "");

const TIME_ORIGINAL_ESTIMATE = "timeoriginalestimate";

export function toFieldSchemaType(schema: JiraFieldSchema | undefined, value: string) {
  if (schema?.system === TIME_ORIGINAL_ESTIMATE || schema?.type === JIRA_FIELD_TYPE.STRING) {
    return value;
  } else if (schema?.type === JIRA_FIELD_TYPE.OPTION) {
    return { value };
  } else {
    return Number.isNaN(Number(value)) ? value : parseFloat(value);
  }
}

export const getDateTimeStringFromTimestamp = (timestamp: number, locale = "en-US") => {
  const date = new Date(timestamp);
  const dateTimeFormatter = new Intl.DateTimeFormat(locale, { dateStyle: "long", timeStyle: "medium" });
  return dateTimeFormatter.format(date);
};

export function getErrorStatus(error: unknown) {
  if (error instanceof AxiosError) {
    return error.response?.status;
  }
  return undefined;
}

const DISABLED_FIELDS_IDS = [
  "aggregatetimeoriginalestimate",
  "aggregatetimeestimate",
  "aggregatetimespent",
  "workratio",
];

const SUPPORTED_FIELDS_TYPES = [
  "number",
  "string",
  "option",
  // Partially supported, currently disabled as to https://appfire.atlassian.net/browse/POK-561
  // "priority",
  // "sd-customerrequesttype",
  // "team",
  // "version",
];

export const isEstimationFieldSupported = (field: JiraField) => {
  if (!field?.schema?.type) return false;
  if (!SUPPORTED_FIELDS_TYPES.includes(field.schema.type)) return false;
  if (DISABLED_FIELDS_IDS.includes(field.id)) return false;
  if (!isStringTypeSupported(field)) return false;
  return true;
};

export const getDefaultEstimationField = (fields?: JiraField[], lastGame?: Game) => {
  return fields?.find(
    (field) =>
      field.id === lastGame?.configuration?.estimationFieldId ||
      ["Story Points", "Story point estimate"].includes(field.name),
  );
};

// Jira shows time values this way, so we should show it the same way
const MINUTE_IN_SECONDS = 60;
const HOUR_IN_SECONDS = MINUTE_IN_SECONDS * 60;
const WORK_DAY_IN_SECONDS = 8 * HOUR_IN_SECONDS;
const WORK_WEEK_IN_SECONDS = 5 * WORK_DAY_IN_SECONDS;

export function secondsToJiraTimeRepresentation(seconds: number) {
  const weeks = Math.floor(seconds / WORK_WEEK_IN_SECONDS);
  const remainingSecondsAfterWeeks = seconds % WORK_WEEK_IN_SECONDS;

  const days = Math.floor(remainingSecondsAfterWeeks / WORK_DAY_IN_SECONDS);
  const remainingSecondsAfterDays = remainingSecondsAfterWeeks % WORK_DAY_IN_SECONDS;

  const hours = Math.floor(remainingSecondsAfterDays / HOUR_IN_SECONDS);
  const remainingSecondsAfterHours = remainingSecondsAfterDays % HOUR_IN_SECONDS;

  const minutes = Math.floor(remainingSecondsAfterHours / MINUTE_IN_SECONDS);

  const letters = ["w", "d", "h", "m"];
  const values = [weeks, days, hours, minutes].map((value, index) => ({ value, letter: letters[index] }));

  return values
    .filter(({ value }) => Boolean(value))
    .map((value) => `${value.value}${value.letter}`)
    .join(" ");
}

export function getIframeUrlParameterByName(name: string) {
  return getUrlQueryParameterByName(window.location.search, name);
}

export function getUrlQueryParameterByName(url: string, name: string) {
  const regexp = new RegExp(`[?&]${name}=([^&^#]*)`);
  const urlMatch = regexp.exec(url);
  return urlMatch && decodeURIComponent(urlMatch[1].replace(/\+/g, " "));
}
