import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  AbstractContext,
  AbstractGameController,
  ItemState,
  NotificationPayload,
  NotificationType,
} from "@appfire/poker-core";

import { useEstimationField } from "../../../hooks/useEstimationField";
import { useFinishGame } from "../../../hooks/useFinishGame";
import { useRefetchIssuesById } from "../../../hooks/useRefetchIssuesById";
import { useApplicationContext } from "../../../providers/ApplicationContextProvider";
import { runSaveEstimateAutomation } from "../../../services/automations";
import {
  useGameSetActiveItemId,
  useGameVoteEndTimestampUpdate,
  useGameVoteStartTimestampUpdate,
  useGameVoteUpdate,
  useItemVotingIsRevealedChange,
  useRemoveVotes,
  useSetFinalEstimate,
  useTimerUpdateStopTimestamp,
  useUpdateGameConfiguration,
} from "../../../services/firebase";
import { getIssuesByReference, showFlag } from "../../../services/jira-api";
import { GameState, JiraIssue } from "../../../types";
import { GamePersona } from "../../../types/common";
import { getErrorMessage, toFieldSchemaType } from "../../../utils";
import { isGameAdmin, prepareCards, preparePokerCoreVoting, saveEstimation } from "../../../utils/game";
import { ActiveItemComponent } from "../ActiveItemComponent";
import { GameBacklog } from "../Backlog/GameBacklog";
import { ConsensusConfetti } from "../ConsensusConfetti";
import { EstimationHeader } from "../EstimationHeader/EstimationHeader";
import { GameChildren } from "../GameChildren";
import { useGameIssues } from "../GameIssuesProvider";
import { useGameData } from "../GameProvider";
import { PersonalEstimateSelector } from "../PersonalEstimateSeletor";
import { VotingSummary } from "../VotingSummary/VotingSummary";
import { EmptyBacklogState, EmptyIssueState, EmptyWelcomeState } from "./components/EmptyState";
import { FinishedState } from "./components/FinishedState";
import { IGNORED_ESTIMATE_REFERENCES, ReferenceIssuesPanel } from "./components/ReferenceIssuesPanel";
import { useGameFlags } from "./hooks/useGameFlags";
import { isVotesRevealedNotificationPayload } from "./notificationChecks";

export function GameControls() {
  const { game, gameId, usersDetails } = useGameData();
  const { issues, unestimatedIssues } = useGameIssues();
  const { refetchIssue } = useRefetchIssuesById();
  const gameState = useMemo(() => game.state, [game.state]);
  const isGameFinished = useMemo(() => {
    return gameState === GameState.FINISHED;
  }, [gameState]);
  const [showConfetti, setShowConfetti] = useState(false);
  const updateStopTimestamp = useTimerUpdateStopTimestamp(gameId);

  const updateVoteStartTimestamp = useGameVoteStartTimestampUpdate(gameId);
  const updateVoteEndTimestamp = useGameVoteEndTimestampUpdate(gameId);

  // Be careful about using backlog vs using game.configuration.backlog
  // Because we have an option to hide estimated issues, sometimes operations on backlog may cause problems
  // If we don't hide those issues here select next and remove issue won't work as expected
  const backlog = useMemo(() => {
    if (!game.configuration.backlog) return [];
    if (isGameFinished) return game.configuration.backlog;

    return game.configuration.hideEstimatedIssues ? unestimatedIssues : game.configuration.backlog;
  }, [game.configuration.backlog, game.configuration.hideEstimatedIssues, isGameFinished, unestimatedIssues]);
  // poker-core identifies issues by id, but in db we are identifying them by key
  const convertedIssues = useMemo(() => {
    const ordered: JiraIssue[] = [];
    backlog.forEach((issueKey) => {
      const issue = issues[issueKey];
      if (issue) {
        issue.id = issue.key;
        ordered.push(issue);
      }
    });
    return ordered;
  }, [backlog, issues]);

  const { userAccountId } = useApplicationContext();
  const estimationField = useEstimationField(game.configuration.estimationFieldId);
  const itemVotingIsRevealedChange = useItemVotingIsRevealedChange(gameId);
  const finishGame = useFinishGame(gameId);
  const updateGameConfig = useUpdateGameConfiguration(gameId);
  const setFinalEstimate = useSetFinalEstimate(gameId);
  const removeVotes = useRemoveVotes(gameId);
  const updateVote = useGameVoteUpdate(gameId);
  const participants = useMemo((): GamePersona[] => {
    return Object.values(game.participants).map((participant) => {
      const userDetails = usersDetails[participant.accountId];
      return {
        id: participant.accountId,
        isOnline: Boolean(participant.instances),
        isModerator: isGameAdmin(participant.accountId, game.configuration.admins),
        isSpectator: participant.isSpectator,
        displayName: userDetails?.displayName,
        avatarUrl: userDetails?.avatarUrls["16x16"],
      };
    });
  }, [game.configuration.admins, game.participants, usersDetails]);

  const activeItemId = useMemo((): string | undefined => {
    // Prevents UI jumping when activeItemId is not in the backlog
    // This can happen when user hides estimated issues
    if (game.activeItemId && !backlog.includes(game.activeItemId)) {
      return backlog[0];
    }

    return game.activeItemId;
  }, [backlog, game.activeItemId]);
  const gameSetActiveItemId = useGameSetActiveItemId(gameId);
  const setActiveItemId = useCallback(
    (issue?: JiraIssue) => {
      const promises = [
        gameSetActiveItemId(issue?.key ?? null),
        updateStopTimestamp(game.timer.autoStartOnNextRound ? Date.now() + game.timer.selectedDuration : -1),
      ];

      if (issue?.key && !game.voting?.[issue.key]?.votingStart) {
        promises.push(updateVoteStartTimestamp(issue.key));
      }

      return Promise.all(promises);
    },
    [
      gameSetActiveItemId,
      updateStopTimestamp,
      game.timer.autoStartOnNextRound,
      game.timer.selectedDuration,
      game.voting,
      updateVoteStartTimestamp,
    ],
  );

  const getContext = useCallback(
    (activeItem?: JiraIssue): AbstractContext<JiraIssue> => {
      if (!estimationField) return {};

      return {
        item: activeItem,
        itemType: activeItem?.fields.issuetype,
        estimationField: estimationField.clauseNames[0],
      };
    },
    [estimationField],
  );

  const getCards = useCallback(() => prepareCards(game.configuration), [game.configuration]);

  const areReferenceItemsEnabled = useMemo(
    () => Boolean(game.activeItemId) && game.configuration.enableEstimationContext,
    [game.activeItemId, game.configuration.enableEstimationContext],
  );
  const getReferenceIssues = useCallback(
    async (estimationField: string, estimate: string, _itemType?: string, item?: JiraIssue): Promise<JiraIssue[]> => {
      if (!item || IGNORED_ESTIMATE_REFERENCES.includes(estimate)) return Promise.resolve([]);

      try {
        const referenceIssues = await getIssuesByReference({
          referenceIssue: item,
          estimationField,
          estimate: estimate,
          additionalUserQuery: game.configuration.limitEstimationContext
            ? game.configuration.estimationContextCustomQuery
            : "",
        });

        return referenceIssues;
      } catch (err) {
        return Promise.resolve([]);
      }
    },
    [game.configuration],
  );

  useGameFlags();
  const onItemStateChange = useCallback(
    (item: JiraIssue, state: ItemState) =>
      Promise.all([itemVotingIsRevealedChange(item.id, state), updateStopTimestamp(-1)]),
    [itemVotingIsRevealedChange, updateStopTimestamp],
  );

  const gameVotes = useMemo(() => preparePokerCoreVoting(game.voting), [game.voting]);

  const onRemoveIssue = useCallback(
    async (issue: JiraIssue) => {
      if (!game.configuration.backlog) return;

      // Must use game.configuration.backlog instead of backlog to not remove estimated issues
      const filteredBacklog = game.configuration.backlog.filter((id) => id !== issue.id);
      await updateGameConfig({ backlog: filteredBacklog });
    },
    [game.configuration.backlog, updateGameConfig],
  );

  const onFinalEstimateSave = useCallback(
    async (issue: JiraIssue, value?: string) => {
      if (!value) {
        throw new Error("No value selected to save!");
      }
      const fieldValue = toFieldSchemaType(estimationField?.schema, value);
      try {
        await saveEstimation(issue.key, game.configuration.estimationFieldId, fieldValue);
        await runSaveEstimateAutomation(issue.key, game.configuration, refetchIssue);
        await setFinalEstimate(issue.key, value);
        // Assuming that it should always be overwritten, because voting didn't end before
        await updateVoteEndTimestamp(issue.key);
      } catch (error) {
        const message = getErrorMessage(error);
        showFlag("Failed to save estimate", message, "error");
        throw new Error(message);
      }
    },
    [estimationField?.schema, game.configuration, refetchIssue, setFinalEstimate, updateVoteEndTimestamp],
  );

  const onVotesReset = useCallback(
    (issue: JiraIssue) =>
      void Promise.all([removeVotes(issue.key), itemVotingIsRevealedChange(issue.key, ItemState.PERSONAL_ESTIMATION)]),
    [itemVotingIsRevealedChange, removeVotes],
  );

  const onItemVoteChange = useCallback(
    (issue: JiraIssue, value: string | null) => updateVote(value, issue.key),
    [updateVote],
  );

  const onNotification = useCallback((type: NotificationType, payload: NotificationPayload<JiraIssue, GamePersona>) => {
    if (isVotesRevealedNotificationPayload(type, payload)) {
      if (payload.hasConsensus) {
        setShowConfetti(true);
      }
    }
  }, []);

  useEffect(() => {
    setShowConfetti(false);
  }, [game.activeItemId]);

  useEffect(() => {
    if (backlog.length === 0) {
      void gameSetActiveItemId(null);
    }
  }, [backlog, gameSetActiveItemId]);

  const isBacklogEmpty = backlog.length === 0;
  const emptyStateComponent = useMemo(() => {
    if (!isGameFinished && !isBacklogEmpty && !gameVotes) {
      return EmptyWelcomeState;
    } else if (!isGameFinished && isBacklogEmpty) {
      return EmptyBacklogState;
    } else if (!isGameFinished) {
      return EmptyIssueState;
    }
  }, [isGameFinished, gameVotes, isBacklogEmpty]);
  const isFinishedOrEmptyBacklogState = isGameFinished || isBacklogEmpty;
  const estimatedItemComponent = useMemo(() => {
    if (!isFinishedOrEmptyBacklogState) return ActiveItemComponent;
  }, [isFinishedOrEmptyBacklogState]);
  const estimationHeaderComponent = useMemo(() => {
    if (!isFinishedOrEmptyBacklogState) return EstimationHeader;
  }, [isFinishedOrEmptyBacklogState]);
  const personalEstimateSelectorComponent = useMemo(() => {
    if (!isFinishedOrEmptyBacklogState) return PersonalEstimateSelector;
  }, [isFinishedOrEmptyBacklogState]);
  const finalEstimateSummaryComponent = useMemo(() => {
    if (!isFinishedOrEmptyBacklogState) return VotingSummary;
  }, [isFinishedOrEmptyBacklogState]);
  const referenceItemsComponent = useMemo(() => {
    if (!isFinishedOrEmptyBacklogState) return ReferenceIssuesPanel;
  }, [isFinishedOrEmptyBacklogState]);

  return (
    <>
      <ConsensusConfetti show={showConfetti} />
      <AbstractGameController
        gameState={gameState}
        currentUserId={userAccountId}
        participants={participants}
        activeItemId={activeItemId}
        items={convertedIssues}
        unestimatedItemsIds={unestimatedIssues}
        votes={gameVotes}
        areReferenceItemsExpanded={areReferenceItemsEnabled}
        showPersonalEstimateSelectorForSpectator
        isAsync={false}
        asyncPhase={undefined}
        everyParticipantCanModerate={false}
        getContext={getContext}
        getCards={getCards}
        getReferenceItems={getReferenceIssues}
        emptyStateComponent={emptyStateComponent}
        estimatedItemComponent={estimatedItemComponent}
        itemsBacklogComponent={GameBacklog}
        estimationHeaderComponent={estimationHeaderComponent}
        personalEstimateSelectorComponent={personalEstimateSelectorComponent}
        finalEstimateSummaryComponent={finalEstimateSummaryComponent}
        gameFinishedComponent={FinishedState}
        referenceItemsComponent={referenceItemsComponent}
        onActiveItemChange={setActiveItemId}
        onItemStateChange={onItemStateChange}
        onFinishGame={finishGame}
        onItemRemove={onRemoveIssue}
        onFinalEstimateSave={onFinalEstimateSave}
        onItemVotesReset={onVotesReset}
        onItemVoteChange={onItemVoteChange}
        onNotification={onNotification}
      >
        {(controllerElement) => {
          return <GameChildren controllerElement={controllerElement} />;
        }}
      </AbstractGameController>
    </>
  );
}
